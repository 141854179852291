import {
  collection,
  DocumentData,
  Firestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import React from "react";

export default function Inbox({ db, uuid }: { db: Firestore; uuid: string }) {
  const [documents, setDocuments] = React.useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  React.useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "channels", uuid, "messages"),
        orderBy("createdAt", "desc"),
        limit(100)
      ),
      (snapshot) => {
        setDocuments(snapshot.docs);
      }
    );
    return unsubscribe;
  }, [db, uuid]);

  return (
    <div>
      <h1>Inbox for {uuid}</h1>
      {documents.map((message) => (
        <div key={message.id}>
          {(() => {
            // if message.data is an object, then we want to show the object
            // otherwise, render the json representation
            if (typeof message.get("data") === "string") {
              return (
                <>
                  <hr />
                  <pre>{message.get("data")}</pre>
                </>
              );
            }
            return (
              <>
                <hr />
                <div>From: {message.get("data.From")}</div>
                <div>
                  Body: <pre>{message.get("data.Body")}</pre>
                </div>
              </>
            );
          })()}
        </div>
      ))}
    </div>
  );
}
