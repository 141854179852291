import { FirebaseApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import Inbox from "./Inbox";

function App({ app }: { app: FirebaseApp }) {
  const uuid = uuidv4();

  const db = React.useMemo(() => getFirestore(app), [app]);

  // if the location is /inbox/uuid, then we want to show the inbox
  const path = window.location.pathname;
  if (path.startsWith("/inbox/")) {
    const uuid = path.split("/")[2];
    return <Inbox uuid={uuid} db={db} />;
  }

  return (
    <div className="App">
      <h1>SMSBox</h1>
      <main>
        <p>Share your Twilio's phone number with others.</p>
        <p>Why would I do such a thing?</p>
        <p>
          Turns out sometimes you just need to share MFA codes or SMS messages.
          This makes a nice page for you to read them on.
        </p>
        <h2>Instructions</h2>
        <ol>
          <li>
            Open the{" "}
            <a href="https://console.twilio.com/us1/develop/phone-numbers/manage/incoming">
              Twilio Console
            </a>
          </li>
          <li>Find the phone number you want to share or buy a new one</li>
          <li>Click on the phone number</li>
          <li>Scroll down to "Messaging"</li>
          <li>
            Set the webhook to <code>https://smsbox.cc/webhooks/{uuid}</code>
          </li>
          <li>Click "Save"</li>
        </ol>
        <p>... and you're done! To view a phone number's inbox, go to</p>
        <a href={`/inbox/${uuid}`}>https://smsbox.cc/inbox/{uuid}</a>
      </main>
    </div>
  );
}

export default App;
